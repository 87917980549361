import Client from '@/utils/client';
import { loadJSON } from '@/utils/load_json';

const activityTarget = loadJSON("activity-target-data");

export async function recordActivity(activityName) {
    if (activityTarget) {
        return await Client.post("/activity", {
            name: activityName,
            target_type: activityTarget.targetType,
            id: activityTarget.id
        });
    }
}

if (!window.targetViewed) {
    window.targetViewed = true;

    recordActivity("View");
}
